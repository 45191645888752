<template>
  <div class="about-container contact-container">
    <Header></Header>
    <div class="container">
      <div class="first">
        <h1 class="fs-64">Contact US</h1>
        <p class="fs-20">
          <b class="fs-20">Phone：</b>
          <a href="tel:008618021052066" class="fs-20">008618021052066</a>
        </p>
        <p class="fs-20">
          <b class="fs-20">Email：</b>
          <a href="mailto:sales01@leadspeedintl.com" class="fs-20">sales01@leadspeedintl.com</a>
        </p>
      </div>
      <div class="banner"></div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "../components/header";
import Footer from "../components/footer";

export default {
  name: "Contact",
  components: {
    Header,
    Footer,
  },

  created() {},
};
</script>
<style lang="scss">
footer {
  padding: 0.520835vw 0px; //10px
  span,
  a {
    font-size: 0.833333vw; //12px
  }
}
.contact-container .header .fr > ul {
  li.el-menu-item:nth-child(4),
  li li:nth-child(4) {
    background-color: #f47a2c;
    color: white;
    a {
      color: white;
    }
  }
}
</style>
